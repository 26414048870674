<template>
  <div class="interval">
    <div class="interval1" ref="scroll_bar">
      <div class="list" v-if="policyDataList.length">
        <div class="item" v-for="(item, index) in policyDataList" :key="index">
          <div class="title">{{ parseTypeText[item.status] }}</div>
          <div class="body">
            <div class="img">
              <div class="cover"  v-if="item.batchtype==='IMG'">
                <div class="onlyimg">
                  <md-swiper :options="swiperOption" v-if="item.imgs?item.imgs.length:0" :is-prevent="false">
                    <md-swiper-item
                      v-for="(value, index) of item.imgs"
                      :key="index"
                      class="co_swiper"
                    >
                      <div class="imgs" @click="preViewImg(item.imgs)">
                        <img :src="ossurl + value.picurl + screenshot" />
                      </div>
                    </md-swiper-item>
                    <div
                      class="swiper-button-prev swiper-button-white"
                      slot="button-prev"
                    ></div>
                    <div
                      class="swiper-button-next swiper-button-white"
                      slot="button-next"
                    ></div>
                  </md-swiper>
                </div>
              </div>
              <div class="txtpolicy" v-if="item.batchtype==='TXT'&&item.txtpolicy">
              <div class="textleft">
                 <p> <span>保险公司名称：</span> <span>{{item.txtpolicy.mainrisk.companyname}}</span></p>
                 <p> <span>产品名称：</span> <span>{{item.txtpolicy.mainrisk.classname}}</span></p>
                 <!-- <p> <span>险种类型：</span> <span>{{item.txtpolicy.mainrisk.classtype}}</span></p> -->
                 <p> <span>保费：</span> <span>{{item.txtpolicy.mainrisk.mount}}</span></p>
                 <p> <span>保额：</span> <span>{{item.txtpolicy.mainrisk.amount}}</span></p>
                 <p> <span>支付方式：</span> <span>{{item.txtpolicy.mainrisk.paycode}}</span></p>
                 <p> <span>缴费年限：</span> <span>{{item.txtpolicy.mainrisk.yearnum}}</span></p>
              </div>
            </div>
            <div class="txtpolicy" v-if="item.batchtype==='BD'">
              <div class="textleft">
                 <p> <span>保险公司名称：</span> <span>{{item.txtpolicy.mainrisk.companyname}}</span></p>
                 <p> <span>产品名称：</span> <span>{{item.txtpolicy.mainrisk.classname.length > 10 ? (item.txtpolicy.mainrisk.classname.slice(0,14) + '...') : item.txtpolicy.mainrisk.classname}}</span></p>
                 <!-- <p> <span>险种类型：</span> <span>{{item.txtpolicy.mainrisk.classtype}}</span></p> -->
                 <p> <span>保费：</span> <span>{{item.txtpolicy.mainrisk.mount}}</span></p>
                 <p> <span>保额：</span> <span>{{item.txtpolicy.mainrisk.amount}}</span></p>
                 <p> <span>支付方式：</span> <span>{{item.txtpolicy.mainrisk.paycode}}</span></p>
                 <p> <span>缴费年限：</span> <span>{{item.txtpolicy.mainrisk.yearnum}}</span></p>
              </div>
            </div>
              <div class="btn" v-if="item.status == 0">
                <router-link
                  tag="span"
                  :to="{
                    path: '/policydetail',
                    query: {
                      comid: item.comid,
                      yzm: item.yzm,
                      reportno: item.reportno,
                      policyno: item.policyno,
                      personkey: personkey,
                      empno: $route.query.empno,
                      from: 'upload',
                      familyno: $route.query.familyno,
                      familyname: $route.query.familyname,
                      fromwhere: $route.query.fromwhere
                    }
                  }"
                >
                  <md-button plain inline type="default" size="small"
                    >查看保单</md-button
                  >
                </router-link>
              </div>
              <div class="line-wrapper">
                <div class="line"></div>
              </div>
            </div>
            <div class="cell">
              <span class='cell_title'>上传时间</span>
              <span class="cell_nr">{{item.intime}}</span>
            </div>
            <div class="cell">
              <span class="cell_title">{{
                item.status == "0"
                  ? "保单号："
                  : item.status === "1"
                  ? ""
                  : "解析失败原因："
              }}</span>
              <span class="cell_nr">{{
                item.status == "0"
                  ? item.policyno
                  : item.status === "1"
                  ? ""
                  : item.remark
              }}</span>
            </div>
            <div class="cell">
              <span class="cell_title">保单上传批次</span>
              <span class="cell_nr">{{ item.batchno }}</span>
            </div>
          </div>
        </div>
      </div>
      <md-result-page
        :img-url="require('@/assets/abd/image/null.png')"
        v-else
        subtext="要不然刷新试试？"
      />
      <md-image-viewer v-model="viewImgSwitch" :list="viewImgList" />
    </div>

    <md-dialog title="请选择客户：" :closable="true" v-model="opendialog">
      <md-field>
        <div class="checkcustom" v-for="(item, index) in listdata" :key="index">
          <md-cell-item :title="item.pname" :brief="item.cardno">
            <md-switch
              v-model="open[index]"
              :disabled="showswitch[index]"
              slot="right"
            />
          </md-cell-item>
        </div>
        <span style="font-weight:bold" v-if="showinput">手动输入:</span>
        <md-field-item
          title="证件类型："
          :content="cardtype"
          v-if="showinput"
          @click="showcardSelector"
          class="require"
          arrow
          :solid="false"
        >
        </md-field-item>
        <md-selector
          v-model="cardSelectorShow"
          :data="cardselectdata"
          title="证件类型"
          default-value="1"
          large-radius
          @choose="oncardtypeChoose"
        ></md-selector>
        <md-input-item
          ref="input0"
          title="证件号码："
          v-if="showinput"
          v-model="cardnum"
          placeholder="证件号码"
        ></md-input-item>
        <md-input-item
          title="姓名："
          v-if="showinput"
          v-model="cardname"
          placeholder="姓名"
        ></md-input-item>
        <md-button type="primary" @click="toreportdetail">生成报告</md-button>
      </md-field>
    </md-dialog>
    <div :class="personkey==='1'?'navsetting1':'navsetting'" :style="'background:'+getStorage('theme','')">
      <div class="backbtn" @click="backtopage" :style="'height:'+(personkey==='1'?'100%':'50%')">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome" @click="backtohomepage" v-if="personkey === '0'">
        <span class="iconfont iconhome"></span>
      </div>
    </div>
    <!-- <div class="backbtn" @click="backtopage">back</div>
    <div class="backtohome" @click="backtohomepage" v-if="personkey === '0'">home</div> -->
  </div>
</template>
<script>
import {
  Dialog,
  Button,
  Toast,
  Field,
  CellItem,
  Switch,
  InputItem
} from 'mand-mobile'
import { checkImgFile, convertPath, getStorage } from '@/lib/util'
import {
  uploads,
  policyList,
  getinsuredList,
  personAnalysis,
  createfirst,
  lookAnalysis
} from '@/api/abd/customer'
import { getSelectList } from '@/api/agent/agent'
import lrz from 'lrz'
import dialog from '@/components/dialog'
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
const { redirect_uri } = config
export default {
  components: {
    dig: dialog,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Field.name]: Field,
    [CellItem.name]: CellItem,
    [Switch.name]: Switch,
    [InputItem.name]: InputItem
  },
  name: 'upload',
  data () {
    return {
      /* 缩略图 */
      screenshot: '?x-oss-process=image/resize,w_100',
      // 弹窗部分
      showinput: true,
      listdata: [],
      showswitch: [],
      currentname: '',
      currenttype: '',
      currentnum: '',
      cardtype: '',
      cardnum: '',
      cardname: '',
      cardtypevalue: '',
      cardSelectorShow: false,
      cardselectdata: [],
      open: [],
      opendialog: false,
      //
      imageLs: [],
      isDetail: false,
      isSubmit: false,
      isCreate: false,
      viewImgSwitch: false,
      viewImgList: [],
      initIndex: 0,
      /* 保单解析 */
      policyDataList: [],
      parseTypeText: [
        '恭喜！您的保单解析成功...',
        '正在努力为您解析保单数据...',
        '抱歉！您的保单解析失败...'
      ],
      pageSize: 40,
      pageNo: 1,
      loading: false,
      total: 0,
      totalPage: 0,
      swiperOption: {
        slidesPerView: 3,
        freeMode: true,
        observer: true,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hideOnClick: true
        }
      },
      loadMore: false,
      ossurl: '',
      user: {},
      personkey: ''
      // viewImgSwitch: false,
      // viewImgList: []
    }
  },
  created () {
    this.personkey = this.$route.query.personkey
    this.user = getStorage('u_s', {})
    this.getData()
    this.ossurl = this.$store.getters.getossurl
    this.ossurl = getStorage('ossurl', '')
  },
  watch: {
    open (newarr) {
      if (newarr.length !== 0) {
        newarr.forEach((item, index) => {
          if (item === true) {
            this.currenttype = this.listdata[index].cardtype
            this.currentnum = this.listdata[index].cardno
            this.currentname = this.listdata[index].pname
            this.showinput = false
            this.showswitch.length = this.listdata.length
            this.showswitch.forEach((item, inx) => {
              if (inx !== index) this.showswitch[inx] = false
            })
          }
        })
      }
      let flag = 0
      newarr.forEach((item, index) => {
        if (item) flag = 1
      })
      if (flag === 0) this.showinput = true
    }
  },
  methods: {
    preViewImg (i) {
      let arr = []
      i.forEach(item => { arr.push(this.ossurl + item.picurl) })
      this.viewImgSwitch = true
      this.viewImgList = arr
    },
    async addImg (e) {
      let file = e.target.files[0]
      if (!checkImgFile(file.name)) {
        Toast.info('请上传图片文件 !')
      } else if (this.imageLs.length >= 4) {
        Toast.info('最多上传4张保单!')
      } else {
        let reslut = await lrz(file, { quality: 0.9 })
        this.imageLs.push({
          src: convertPath(file),
          file: reslut.base64.replace(/^data:image\/\w+;base64,/, '')
        })
        e.target.value = ''
      }
    },
    showRule () {
      this.isDetail = !this.isDetail
    },
    dleImg (i) {
      this.imageLs.splice(i, 1)
    },
    downloadImg () {
      if (!this.imageLs.length) {
        Toast.info('请上传图片文件 !')
      } else {
        this.isSubmit = true
        uploads({
          file: this.imageLs.map(files => files.file),
          prefix: 'policy',
          userid: this.$route.query.userid
        }).then(res => {
          if (res.data.code === 200) {
            this.isSubmit = true
            this.imageLs = []
            Toast.succeed('上传成功')
            this.getData()
          }
        })
      }
    },
    /* 保单解析 */

    getData (isInit = true) {
      let { pageSize, pageNo } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      policyList({
        familyno: this.$route.query.familyno,
        comempno: this.$route.query.empno || this.user.empno,
        page: pageNo,
        size: pageSize
      }).then(res => {
        res.data.data.rows.forEach(item => { if (item.txtmsg) item.txtpolicy = JSON.parse(item.txtmsg) })
        res.data.data.rows.forEach(item => { if (item.txtmsg && item.batchtype === 'BD') item.txtpolicy = JSON.parse(item.txtmsg)[0] })
        this.policyDataList = res.data.data.rows
      })
    },
    createReport () {
      if (this.$route.query.reportno !== '') {
        this.$router.push({
          path: '/detail',
          query: {
            name: this.$route.query.name,
            sex: this.$route.query.sex,
            cardno: this.$route.query.cardno,
            cardtype: this.$route.query.cardtype,
            reportno: this.$route.query.reportno
          }
        })
      } else {
        if (this.$route.query.cardno === '') {
          this.opendialog = true
          getinsuredList({ userid: this.$route.query.userid }).then(res => {
            this.listdata = res.data.data
          })
        } else {
          personAnalysis({
            cardno: this.$route.query.cardno,
            cardtype: this.$route.query.cardtype
          }).then(res => {
            this.$router.push({
              path: '/detail',
              query: {
                name: this.$route.query.name,
                sex: this.$route.query.sex,
                cardno: this.$route.query.cardno,
                cardtype: this.$route.query.cardtype,
                reportno: res.data.data
              }
            })
          })
        }
      }
    },
    toreportdetail () {
      if (
        this.currentnum !== '' ||
        this.currenttype !== '' ||
        this.currentname !== ''
      ) {
        createfirst({
          cardtype: this.currenttype,
          cardno: this.currentnum,
          userid: this.$route.query.userid,
          name: this.currentname
        }).then(res => {
          personAnalysis({
            cardno: this.currentnum,
            cardtype: this.currenttype
          }).then(res => {
            this.$router.push({
              path: '/detail',
              query: {
                name: this.$route.query.name,
                sex: this.$route.query.sex,
                cardno: this.$route.query.cardno,
                cardtype: this.$route.query.cardtype,
                reportno: res.data.data
              }
            })
          })
        })
      } else {
        if (
          this.cardtypevalue === '' ||
          this.cardnum === '' ||
          this.cardname === ''
        ) {
          Toast.failed('请填写证件信息')
        } else {
          createfirst({
            cardtype: this.cardtypevalue,
            cardno: this.cardnum,
            userid: this.$route.query.userid,
            name: this.cardname
          }).then(res => {
            personAnalysis({
              cardno: this.cardnum,
              cardtype: this.cardtypevalue
            }).then(res => {
              this.$router.push({
                path: '/detail',
                query: {
                  name: this.$route.query.name,
                  sex: this.$route.query.sex,
                  cardno: this.$route.query.cardno,
                  cardtype: this.$route.query.cardtype,
                  reportno: res.data.data,
                  empno: this.$route.query.empno
                }
              })
            })
          })
        }
      }
    },
    showcardSelector () {
      this.cardSelectorShow = true
    },
    oncardtypeChoose ({ value, text }) {
      this.cardtype = text
      this.cardtypevalue = value
    },
    backtopage(){ 
      this.$router.push({path:'/customercenter',query:{
        familyname: this.$route.query.familyname,
        familyno: this.$route.query.familyno,
        fromwhere: this.$route.query.fromwhere
      }})},
      backtohomepage(){this.$router.push({path:'/bdtg',query:{fromwhere: this.$route.query.fromwhere,bdtgflush: true }})}
    // }
  }
}
</script>
<style scoped lang="stylus">
/deep/ .md-dialog-content {
  width: 80vw;
}
.body {
  margin-top: 40px;
}
.top_tip {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rule span {
  color: #85888c;
  font-size: 24px;
}
.rule_gz {
  text-decoration: underline;
}

.body_boder {
  text-align: center;
  border: 1px dashed #afafaf;
  width: 444px;
  height: 598px;
  margin: 0 auto;
  position: relative;
}
.body_opacity {
  height: 556px;
  background-color: rgba(242, 247, 252, 0.7);
  color: rgb(0, 0, 0);
}
.body_addimg {
  background: url("../../../../assets/abd/image/imgmsk.png") no-repeat center;
  background-size: 100% 100%;
  /* height:100%; */
  width: 90%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cent .icon {
  position: relative;
  width: 111px;
  height: 111px;
  border-radius: 50%;
  background-color: #2badda;
  margin: 0 auto 20px;
}
.add_tip {
  width: 300px;
}
.add_tip h1 {
  font-size: 28px;
  color: #1e1e1e;
  /* font-weight: 800; */
}
.add_tip span {
  font-size: 20px;
  color: #666666;
}
.icon input {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.tip {
  padding-left: 50px;
  margin-top: 40px;
}
.tip span {
  color: #85888c;
  font-size: 24px;
}

.foot_btn {
  text-align: center;
  padding-top: 43px;
  /*margin-bottom: 200px;*/
}
.foot_btn button {
  background: #3296fa;
  margin: 50px auto;
  height: 91px;
}
.report_btn {
  width: 100%;
  text-align: center;
}
.report_btn button {
  background: #3296fa;
  height: 91px;
}
p {
  margin-bottom: 30px;
  font-size: 28px;
  color: #606060;
}
p:last-child {
  color: #9e9e9e;
  font-size: 26px;
  margin: 0;
}
p span {
  color: #036eb5;
}
.container {
  padding: 50px 50px 0;
  height: 100%;
  background-color: #ffffff;
}
/*.title {*/
/*  width: 90%;*/
/*  height: 280px;*/
/*}*/
.totutorial a {
  color: #85888c;
  font-size: 24px;
  text-decoration: underline;
}
.iconxiangji3 {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cs {
  font-size: 60px !important;
}
.bd_img {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
}
.bd_img > div {
  height: 422px;
  width: 49%;
  position: relative;
  border: 1px dashed #afafaf;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}
.item .icon {
  position: absolute;
  bottom: 11px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #2fb2fa;
}
.item .icon:nth-child(2) {
  right: 27px;
}
.item .icon:last-child {
  right: 100px;
}
.item .icon span {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 51%;
  transform: translate(-50%, -50%);
  font-size: 28px;
}
.itemt {
  position: relative;
}
.itemt .body_opacity {
  height: 422px;
}

/*保单解析*/

.onlyimg {
  height: 400px;
}
.onlyimg img,
.imgs img {
  object-fit: cover;
}
.icon {
  text-align: center;
}
.list {
  height: 100%;
}
.iconfont {
  font-size: 120px;
}
.success {
  color: #2fb2fa;
}
.error {
  color: #e01504;
}
.line-wrapper {
  padding: 30px 0;
}
.line {
  border-bottom: 1px dashed #e9e9e9;
  position: relative;
}
.line::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f9f9f9;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}
.line::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f9f9f9;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
}
.interval {
  padding: 46px 47px;
  /*height: 500px;*/
  overflow: auto;
  height: 100%;
}
.interval1{
  width 100%
  height 100%
}
/*.interval::-webkit-scrollbar {*/
  /*display: none;*/
/*}*/
.btn {
  margin-top: 20px;
}
.btn button {
  border: 1px dashed #85888c;
}
.item {
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px #e3e8f7;
  margin-bottom: 20px;
  background: #fff;
}
.item:last-child {
  margin: 0;
}
.body {
  padding-top: 20px;
}
.img {
  text-align: center;
}
/* .cover {
      width: 800px;
      display: inline-block;
    } */
.co_swiper {
  width: 879px;
}
.co_img {
  height: 366px;
  width: 343px;
  padding-right: 50px;
}
.title {
  font-size: 36px;
  /* font-weight: bold; */
  padding: 20px 0;
  text-align: center;
  color: #1e1e1e;
}
.cell {
  line-height: 60px;
  font-size: 28px;
  color: #1e1e1e;
  /* font-weight: 500; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img a {
  display: block;
  font-size: 26px;
}

.cell_nr {
  color: #666666;
  font-size: 26px;
}
.cell_title {
  color: #1e1e1e;
  font-size: 28px;
  /* font-weight: 500; */
}
.txtpolicy{
  display flex
  width: 100%;
  height: 100%;
  border 1px dashed #E9E9E9
  .textleft{
    span:nth-(2n-1){
        text-align: right;
    }
    width 100%
    p{
      display flex
      justify-content space-between
      padding 7px 10px
    }
  }
}
.navsetting{
  width 10vw
  height 20vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.navsetting1{
  width 10vw
  height 10vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.backbtn{
  width 80%
  height 50%
  margin 0 auto
  border-bottom 0.8px solid rgba(255,255,255,0.5)
  display flex
  justify-content center
  align-items center
  span{
    width 100%
    display flex
    justify-content center
    align-items center
    color white
    font-size 50px
  }
}
.backtohome{
  width 80%
  height 50%
  margin 0 auto
  display flex
  justify-content center
  align-items center
  span{
    display flex
    justify-content center
    align-items center
    width 100%
    color white
    font-size 50px
  }
}
</style>
