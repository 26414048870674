/*
 * @Author: 王广婷
 * @Date: 2019-10-31 09:24:19
 * @LastEditors: 贾峰
 * @LastEditTime: 2019-11-26 20:38:32
 */

import axios from '@/lib/api.request'

// 保单上传
export const uploads = data =>
  axios.post('/abd/abtComAbdPolicyImg/add', data)

// 保单解析列表
export const policyList = data =>
  axios.post('/abd/muser/getUserBdList', data)

// 查询保单报告
export const queryPolicy = params =>
  axios.get('/abd/abtComAbdReportlist/show', { params })

// 客户列表
export const getlist = data =>
  axios.post('/abd/muser/list', data)

// 添加客户
export const addCustomer = data =>
  axios.post('/abd/muser/createQr', data)
export const confirm = data =>
  axios.post('/abd/muser/setShip', data)

// detail 生成报告
export const personAnalysis = data =>
  axios.post('/abd/muser/getAnalysis', data)

// 获取无身份证号弹窗列表
export const getinsuredList = data =>
  axios.post('/abd/muser/insuredList', data)

// 点击生成报告调的第一个
export const createfirst = data =>
  axios.post('/abd/muser/addBasemsg', data)
